import React from 'react'

const AccordionAction = (props) => {
    return (
        <button
            className="btn">
            {props.children}
        </button>
    )
}
export default AccordionAction