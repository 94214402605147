import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getList, showUpdate, remove } from './actions'
import Table from '../../common/layout/Table';
import Paginate from '../../common/layout/Paginate';
import { removePrefix } from '../../common/operator/helpers';

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.update = this.update.bind(this)
    }

    // componentWillMount() {
    //     this.props.getList();
    // }

    update(user) {
        if (this.props.auth_data.profile_id === 1) {
            user.user_type_id = user.user_profiles.map(el => el.profile_id);
        } else {
            user.login = removePrefix(user.login, this.props.profile.noun);
        }
        this.props.showUpdate(user);
    }

    render() {
        return (
            <>
                <Paginate data={this.props.list} source={this.props.getList} />
                <Table body={this.props.list?.data} actions={{ update: this.update, remove: this.props.remove }}
                    attributes={{ login: 'Login', name: 'Nome', email: 'E-mail', user_situation_id: 'Status' }}
                    translate={{
                        user_situation_id: [
                            { id: 0, name: 'Desativado' },
                            { id: 1, name: 'Ativo' }
                        ]
                    }} />
            </>
        )
    }
}

const mapStateToProps = state => ({ list: state.user.list, auth_data: state.auth.token, profile: state.auth.profile })
const mapDispatchToProps = dispatch => bindActionCreators({ getList, showUpdate, remove }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)