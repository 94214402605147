import React from 'react'

const Accordion = (props) => {
    return (
        <div className="card" ref={props.innerRef} {...props}>
            {props.children}
        </div>
    )
}

export default Accordion;