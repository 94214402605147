import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import ExternalForm from '../app/ExternalForm'

import Auth from '../default/auth/auth'

export default props => (
    <div>
        <Switch>
            <Route key="/" exact={true} path="/" component={Auth} />
            <Route key="/form/:hash" path="/form/:hash" component={ExternalForm} />
            <Redirect from='*' to='/' />
        </Switch>
    </div>
)