import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

const INITIAL_VALUES = {name: '', login: '', password: '', user_type_id: '', user_situation_id: ''}

export function getList(page = null, limit = null, search = "") {
    const request = axios.get(`${process.env.REACT_APP_API_HOST}/users?with=userProfiles${page !== null ? `&page=${page}&` : `&`}${limit !== null ? `limit=${limit}` : `limit=10`}${(search !== "" || search !== null) ? `&search=name:${search};&searchFields=name:like` : ``}`)
    return {
        type: 'USERS_FETCHED',
        payload: request
    }
}

export function getListTypes() {
    const request = axios.get(`${process.env.REACT_APP_API_HOST}/profiles`)
    return {
        type: 'USER_TYPES_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post')
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete')
}

function submit(values, method) {
    return dispatch => {
        const id = values.id ? values.id+0 : ''
        let filteredValues = {...values}
        if(id) delete filteredValues.id
        delete filteredValues.email_verified_at
        
        axios[method](`${process.env.REACT_APP_API_HOST}/users${id ? `/${id}` : `/`}`, filteredValues)
        .then(resp => {
            toastr.success('Sucesso', 'Operação Realizada com sucesso.')
            dispatch(init())
            dispatch(getList())
        })
        .catch(e => {
            if (!e.response) {
                toastr.error('Erro', 'Desconhecido :-/')
                console.log(e)
            } else if (!e.response.data) {
                toastr.error('Erro', e.response.message)
            } else if (e.response.data.errors) {
                Object.entries(e.response.data.errors).forEach(
                    ([key, error]) => toastr.error(key, error[0]))
            } else if (e.response.data) {
                toastr.error('Erro', e.response.data.message)
            }
        })
    }
}

export function showContent(flag) {
    return {
        type: 'USER_CONTENT_CHANGED',
        payload: flag
    }
}

export function showUpdate(user) {
    user.user_type_id = user.user_profiles.map(el => el.profile_id);
    return [
        showContent('form'),
        initialize('userForm', user)
    ]
}

export function init() {
    return [
        initialize('userForm', INITIAL_VALUES),
        showContent('list')
    ]
}

export function resetComponent() {
    return {
        type: 'USER_RESET',
        payload: null
    }
}