import Dashboard from './Dashboard/dashboard';
import Quizzes from './Quizzes';
import { quizzesReducer } from "./Quizzes/Duck/";
import { quizQuestionsReducer } from './Quizzes/Duck/questions.duck'
import { studentPaymentsReducer } from './Quizzes/Duck/answer.duck'
import { quizQuizAnswersReducer } from './Quizzes/Duck/quizAnswer.duck';
import { formGraphReducer } from './Quizzes/Duck/graphs.duck';
import ExternalForm from './ExternalForm';

// Reducers do projeto
export const reducers = {
    quizzes: quizzesReducer,
    quiz_questions: quizQuestionsReducer,
    quiz_questions_answers: studentPaymentsReducer,
    quiz_quiz_answers: quizQuizAnswersReducer,
    form_graph: formGraphReducer
}

// Rotas do projeto
export const routes = [
    { exact: true, path: '/', component: Dashboard },
    { exact: true, path: '/quizzes', component: Quizzes },
    { exact: true, path: '/form/:hash', component: ExternalForm}
]

// Menu do projeto
export const menu = {
    '/': { title: 'Dashboard', icon: 'handshake-o' },
    '/quizzes': {
        title: 'Questionarios', icon: 'graduation-cap',
    },
}