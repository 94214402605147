import React from 'react'

const AccordionHeader = (props) => {
    return (
        <div className="card-header" id={`heading${props.id}`} style={{backgroundColor: props.color || "#343a40"}}>
            <h5 className="mb-0">
                {props.children}
            </h5>
        </div>
    )
}
export default AccordionHeader