import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//import LabelAndInput from '../../../common/form/LabelAndInput'
import { init, getList, showUpdate, remove, showContent, getXlsfile } from '../Duck/quizAnswer.duck'
import BaseTable from '../../../common/layout/BaseTable';
import Paginate from '../../../common/layout/Paginate';
import Grid from '../../../common/layout/grid';

class FormQuizAnswers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            isEdit: false,
            translate_course: [],
            translate_class: []
        }
        this.init = this.init.bind(this)
        this.showUpdate = this.showUpdate.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.create = this.create.bind(this)
    }

    dateFormat = i => {
        if (i) {
            let date = new Date(i + (i.length > 11 ? '' : ' 00:00:00'))
            date = (date.getDate() < 10 ? '0' : '') + date.getDate() + '/'
                + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear()

            return date
        } else return ''
    }

    onSubmit(question) {
        question.quiz_id = this.props.userId

        if (question.id) {
            this.props.onUpdate(question)
        } else {
            this.props.onCreate(question)
        }
    }

    async downloadXls() {
        const response = await getXlsfile(this.props.userId);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `questionario-${this.props.userId}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }

    create() {
        this.props.showContent('form')
    }

    showUpdate(question) {
        this.props.showUpdate(question)
    }

    init() {
        this.props.init()
    }

    componentDidMount() {
        if (this.props.userId !== null) {
            this.props.getList(0)
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.userId !== undefined && nextProps.userId !== null && this.props.userId === null) {
            nextProps.getList(nextProps.userId)
        }
    }

    change_classes = (value) => {
        if (value !== null || value !== undefined) {
            this.props.getListClass(value)
        }
    }

    renderAnswers = (answers) => {
        return <Grid cols='12 12' style={{ marginLeft: '10px' }}>
            {
                answers.map((answer, index) => {
                    let renderedAnswer = null

                    switch (answer.answer_type) {
                        case 'text':
                        case 'number':
                            renderedAnswer = answer.answer
                            break

                        case 'radio':
                        case 'checkbox':
                            renderedAnswer = answer.answer.answer_name
                            break

                        case 'file':
                            renderedAnswer = <a href={answer.answer} target='_blank' rel="noopener noreferrer">Clique para baixar</a>
                            break

                        case 'date':
                            renderedAnswer = this.dateFormat(answer.answer)
                            if (renderedAnswer.indexOf('NaN') > -1) renderedAnswer = answer.answer
                            break
                        
                        default :
                            break
                        
                    }

                    return <div key={index} className="row" key={index}>
                        <strong>{answer.name || answer.title}</strong> : {renderedAnswer}
                    </div>
                })
            }
        </Grid>
    }

    render() {
        return (
            <>
                <button className="btn btn-primary" onClick={() => this.downloadXls()}>Baixar todos os registros (excel)</button>
                <br /><br />

                {/* <Paginate data={this.props.list} source={this.props.getList}/> */}
                {/* <BaseTable width='500' labelMobile='id ' body={this.props.list} actions={{ remove: this.props.remove }}
                attributes={{ interviewer: "Entrevistador", date: {title:'Data', callback: e => this.dateFormat(e)}, answers: {title: 'Respostas', callback: this.renderAnswers}}} /> */}
                {this.props.answer_map?.map(el => (
                    <>
                        <div className="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        {el.answers.map(e => (
                                            <th scope="col">{e.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {el.answers.map(e => {
                                            let renderedAnswer = '';
                                            switch (e.answer_type) {
                                                case 'text':
                                                case 'number':
                                                    renderedAnswer = e.answer
                                                    break

                                                case 'radio':
                                                case 'checkbox':
                                                    renderedAnswer = e.answer.answer_name
                                                    break

                                                case 'file':
                                                    renderedAnswer = '<a href="' + e.answer.answer + '" target="_blank">Clique para baixar</a>'
                                                    break

                                                case 'date':
                                                    renderedAnswer = e.answer.answer
                                                    break
                                                default:
                                                    console.log("ok");
                                                    break
                                            }
                                            return (
                                                <td scope="col">{renderedAnswer}</td>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                ))}

            </>
        );
    }
}

const mapStateToProps = state => ({
    quiz_id: 0,
    errors: state.quiz_quiz_answers.errors,
    list: state.quiz_quiz_answers.list,
    show: state.quiz_quiz_answers.show,
    listTypes: state.quiz_quiz_answers.listTypes,
    answer_map: state.quiz_questions_answers.map_answer
})
const mapDispatchToProps = dispatch => bindActionCreators({
    init, getList, showUpdate, remove, showContent
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FormQuizAnswers)