import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { showContent } from '../Quizzes/Duck'
import Content from '../../common/template/Content'
import { Field, reduxForm } from 'redux-form'
import If from '../../common/operator/If'
import { getQuestions, getYYYYMMDD, renderInput, resolveAnswer, sendQuestions } from './service'

const styleHeader = {
    backgroundColor: '#2f4475',
    color: '#eee',
    paddingTop: '20px',
    borderRadius: '5px'
} 

class ExternalForm extends Component {
    
    state = {
        quiz_id: 0,
        questData: [],
        questDataTrigger: [],
        title_form: "Form Imesc",
        description_form: "imesc Amplo",
        status: "form"
    }

    handleCustom = (values) => {
        this.setState({status: "loading"})
        var data_to_send = {
            quiz_id: this.state.quiz_id,
			lat: "0",
			long: "0",
			date: getYYYYMMDD(),
			interviewer: "usuario-internet",
            answers: []
        }
        this.state.questData.map(quest => {
            let answer = {
                id: quest.id,
                title: quest.name_question,
                answer_type: quest.answer_type,
                answer: resolveAnswer(values, quest)
            }
            data_to_send.answers.push(answer)
            return true
        })
        console.log(data_to_send)
        sendQuestions(data_to_send).then(status => this.setState({status: "success"})).catch(err => {
            this.setState({status: "form"})
            console.log(err)
        })
    }

    verifyTriggersRadio = (event, key) => {
        const [_, id_quest] = event.target.name.split('@');
        const [question] = this.state.questData.filter(q => q.id === parseInt(id_quest));
        var indexq = this.state.questData.indexOf(question);
        var newQuestData = this.state.questData;
        const option = question.answers[key];
        const triggerToRemove = this.state.questData.filter(el => el.is_from_trigger === 1 && el.question_owner_trigger === question.id);
        if (triggerToRemove.length > 0) {
            newQuestData = newQuestData.filter(el => el.is_from_trigger !== 1 && el.question_owner_trigger !== question.id);
            this.setState({questData: newQuestData})
        }
        if (option.trigger === 1) {
            const questions_to_add = this.state.questDataTrigger.filter(el => el.trigger_answer_id === option.id);
            //question_to_add.question_owner_trigger = question.id;
            questions_to_add.map(el => el.question_owner_trigger = question.id);
            newQuestData = [...newQuestData.slice(0, indexq+1), ...questions_to_add, ...newQuestData.slice(indexq+1)]
            this.setState({questData: newQuestData})
        }
        console.log(option);
    }

    onChangeCheckbox = (event, a, input) => {
        const newValue = [...input.value];
        const [question] = this.state.questData.filter(q => q.id === a.question_id);
        debugger
        if (event.target.checked) {
            if (a.trigger === 1) {
                var indexq = this.state.questData.indexOf(question);
                const questions_to_add = this.state.questDataTrigger.filter(el => el.trigger_answer_id === a.id);
                //question_to_add.question_owner_trigger = question.id;
                questions_to_add.map(el => el.question_owner_trigger = question.id);
                const newQuestData = [...this.state.questData.slice(0, indexq+1), ...questions_to_add, ...this.state.questData.slice(indexq+1)]
                this.setState({questData: newQuestData})
            }
            newValue.push(a.answer_name);
        } else {
            if (a.trigger === 1) {
                const newQuestData = this.state.questData.filter(el => el.is_from_trigger !== 1 && el.question_owner_trigger !== question.id);
                this.setState({questData: newQuestData})
            }
            newValue.splice(newValue.indexOf(a.name), 1);
        }
        return input.onChange(newValue);
    }

    CheckboxGroup = ({ answers, input }) => {
        return (
            <div className="row">
                {
                    answers.map((a, key) => (
                        <div className="custom-control custom-checkbox custom-control-inline col-md-2 col-12" key={a.id}>
                            <input 
                                className="custom-control-input" 
                                type="checkbox" 
                                name={`@${a.question_id}[${key}]`} 
                                id={`exampleCheckbox${a.id}`} 
                                value={`${a.answer_name}`}
                                onChange={(e) => this.onChangeCheckbox(e, a, input)}
                             />
                            <label className="custom-control-label col-12" htmlFor={`exampleCheckbox${a.id}`}>
                                {a.answer_name}
                            </label>
                        </div>
                    ))
                }
            </div>
        )
    }

    componentDidMount() {
        getQuestions(this.props.match.params.hash).then(resp => this.setState(resp))
        .catch(err => this.setState(err))
    }

    returnAnswerType({answer_type, answers, id}) {
        const answer_types = {
            text: () => <Field component="input" className="form-control" type="text" name={`@${id}`} />,
            number: () => <Field component="input" className="form-control" type="number" name={`@${id}`} />,
            date: () => <Field component="input" className="form-control" type="date" name={`@${id}`} />,
            file: () => <Field component={renderInput} className="form-control" type="file" name={`@${id}`} />,
            radio: () => {
                return (
                    <div className="row">
                        {answers.map((a, k) => (
                            <div className="custom-control custom-radio custom-control-inline col-md-2 col-sm-12" key={a.id}>
                                <Field onClick={(e) => this.verifyTriggersRadio(e, k)} component="input" className="custom-control-input" type="radio" name={`@${a.question_id}`} id={`exampleRadios${a.id}`} value={a.answer_name} />
                                <label className="custom-control-label" htmlFor={`exampleRadios${a.id}`}>
                                    {a.answer_name}
                                </label>
                            </div>
                        ))}
                    </div>
                )
            },
            checkbox: () => {
                return <Field name={`@${id}`} component={this.CheckboxGroup} answers={answers} />
            },
        }
        return answer_types[answer_type]();
    }

    render() {
        return (
            <div className="container">
                <section className='content-header'>
                    <div className="container-fluid">
                        <div className="row mb-2" style={styleHeader}>
                            <div className="col-sm-4 col-lg-3" style={{ margin: '20px'}}>
                                <h1 className="pull-left">{this.state.title_form}</h1>
                            </div>
                        </div>
                        <div className="row ml-4">
                            <p>
                                <small>{this.state.description_form}</small>
                            </p>
                        </div>
                    </div>
                </section>
                <Content>
                    <If test={this.state.status === 'form'}>
                        <form onSubmit={this.props.handleSubmit(this.handleCustom)}>
                            {
                                this.state.questData.map(el => {
                                    return (
                                        <div className="card card-primary" key={el.id}>
                                            <div className='card-body'>
                                                <p>{el.name_question}</p>
                                                {this.returnAnswerType(el)}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.questData.length > 0 && (
                                    <div className="card card-primary">
                                        <div className='card-body'>
                                            <button type="submit" className="btn btn-success btn-block">Enviar Formulário</button>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                    </If>
                    <If test={this.state.status === 'loading'}>
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </If>
                    <If test={this.state.status === 'success'}>
                        <div class="alert alert-success" role="alert">
                            <strong>Sucesso!</strong> Obrigado pela sua resposta.
                        </div>
                    </If>
                </Content>
            </div>
        )
    }
}
ExternalForm = reduxForm({ form: 'ExternalForm', destroyOnUnmount: false })(ExternalForm)

const mapStateToProps = state => ({
    show: state.quizzes.show,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    showContent
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ExternalForm)
