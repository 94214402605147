import React, { Component } from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Row from '../../../common/layout/row'
import { init, showContent, getListShifts, getList, showUpdate, remove } from '../Duck/graphs.duck'
import If from '../../../common/operator/If';
import LabelAndSelect from '../../../common/form/LabelAndSelect';
import { Doughnut, Pie, Bar } from 'react-chartjs-2';

const getChart = (phrase) => {
    const charts = {
        pie: Pie,
        doughnut: Doughnut,
        bar: Bar
    }
    return charts[phrase];
};

class FormGraphs extends Component {
    constructor(props) {
        super(props)
        this.state = { tipo: 1 }
        this.init = this.init.bind(this)
        this.showUpdate = this.showUpdate.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.create = this.create.bind(this)
    }

    onSubmit(classes) {
        classes.quiz_id = this.props.userId

        if (classes.id) {
            this.props.onUpdate(classes)
        } else {
            this.props.onCreate(classes)
        }
    }

    create() {
        this.props.showContent('form')
        this.props.getListShifts()
    }

    showUpdate(classes) {
        this.props.getListShifts()
        this.props.showUpdate(classes)
    }

    init() {
        this.props.init()
    }

    componentDidMount() {
        if (this.props.userId !== null) {
            this.props.getList(0)
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.userId !== undefined && nextProps.userId !== null && this.props.userId === null) {
            console.log(nextProps)
            nextProps.getList(nextProps.userId)
        }
    }

    render() {
        return (
            <>
                <If test={this.props.show === 'form'}>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <Row>
                            <Field name='chart_type' component={LabelAndSelect} readOnly={false}
                                label='Escolha o tipo de gráfico' cols='12 4' placeholder='Selecione...'
                                options={[
                                    { id: 'bar', name: 'Barra' },
                                    { id: 'pie', name: 'Pizza' },
                                    { id: 'doughnut', name: 'Donut' }
                                ]} error={this.props.errors} />
                            <Field name='question_id' component={LabelAndSelect} readOnly={false}
                                label='Escolha a alternativa para criar grafico' cols='12 4' placeholder='Selecione...'
                                options={this.props.shifts.map(question => {
                                    return ({
                                        id: question.id,
                                        name: question.name_question,
                                        answer_type: question.answer_type
                                    })
                                }).filter(e => e.answer_type === "radio" || e.answer_type === "checkbox")} error={this.props.errors} />
                        </Row>
                        <Row>
                            <button type='submit' className={`btn btn-primary`}>Salvar</button>
                            <button type='button' className='btn btn-default' onClick={this.props.init}>Cancelar</button>
                        </Row>
                    </form>
                </If>
                <If test={this.props.show === 'list'}>
                    <button className="btn btn-primary" onClick={this.create}>Adicionar Graficos</button>
                    <br /><br />
                    <div className="row">
                        {this.props.list.map(el => {
                            const MyChart = getChart(el.chart_type);
                            return (
                                <div className="col-6">
                                    <p>{this.props.title} - {el.chart_data[0] ? (<strong>{el.chart_data[0].name}</strong>) : 'Sem dados nesse gráfico'}</p>
                                    <p><button style={{ float: 'right' }} onClick={() => this.props.remove(el)} className={`btn btn-danger btn-sm`}>Excluir</button></p>
                                    <MyChart data={{
                                        labels: el.chart_data.map(e => `${e.answer_name}: ${e.count} `) || [],
                                        datasets: [
                                            {
                                                label: '#',
                                                data: el.chart_data.map(e => e.count) || [],
                                                backgroundColor: [
                                                    'rgba(255, 99, 132, 0.7)',
                                                    'rgba(54, 162, 235, 0.7)',
                                                    'rgba(255, 206, 86, 0.7)',
                                                    'rgba(75, 192, 192, 0.7)',
                                                    'rgba(153, 102, 255, 0.7)',
                                                    'rgba(255, 159, 64, 0.7)',
                                                    'rgba(199, 113, 199, 0.7)'
                                                ],
                                                borderColor: [
                                                    'rgba(255, 99, 132, 1)',
                                                    'rgba(54, 162, 235, 1)',
                                                    'rgba(255, 206, 86, 1)',
                                                    'rgba(75, 192, 192, 1)',
                                                    'rgba(153, 102, 255, 1)',
                                                    'rgba(255, 159, 64, 1)',
                                                    'rgba(199, 113, 199, 1)'
                                                ],
                                                borderWidth: 1,
                                            },
                                        ],
                                    }} />
                                </div>
                            )
                        })}
                    </div>
                </If>
            </>

        );
    }
}
FormGraphs = reduxForm({ form: 'FormGraphsForm', destroyOnUnmount: false })(FormGraphs)
const selector = formValueSelector('FormGraphsForm')
const mapStateToProps = state => ({
    course_id: 0,
    chart_type: selector(state, 'chart_type'),
    question_id: selector(state, 'question_id'),
    shifts: state.quiz_questions.list,
    errors: state.form_graph.errors,
    show: state.form_graph.show,
    list: state.form_graph.list,
    title: state.form.QuizForm?.values.name
})
const mapDispatchToProps = dispatch => bindActionCreators({ init, showContent, getListShifts, getList, showUpdate, remove }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FormGraphs)