import React from 'react'
import Grid from '../grid'
import If from '../../operator/If'

export default props => (
    <Grid {...props.grid}>
        <div className={`card bg-${props.bg || ' '}`}>
            <div className="card-body">
                <span className='info-box-text' style={{ fontWeight: 'bold' }}>{props.title}</span>

                <If test={props.text}>
                    <p className='info-box-number'>{props.text}</p>
                </If>

                <If test={props.content}>
                    {props.content}
                </If>

                <If test={props.progress}>
                    <div className='progress'>
                        <div className='progress-bar' style={{ width: props.progress }}></div>
                    </div>
                    <span className='progress-description'>
                        {props.progressText}
                    </span>
                </If>
            </div>
        </div>
    </Grid>
)