import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

// STATES
const INITIAL_STATE = {
    list: [],
    triggerList: [],
    courses: [],
    classes: [],
    map_answer: [],
    show: 'list',
    errors: {}
}

const INITIAL_VALUES = {
    question_id: 0,
    answer_name: 0,
    trigger: false,
    id: 0,
    trigger_question_id: null
}

// ACTIONS
export function getList(id) {
    const request = axios.get(
        `${process.env.REACT_APP_API_HOST}/questions?search=quiz_id:${id}&with=answer`
    )
    return {
        type: 'QUIZ_QUESTION_FETCHED',
        payload: request
    }
}

export function getListClass(id = null) {
    const request = axios.get(
        `${process.env.REACT_APP_API_HOST}/course_classes${id !== null ? `?search=course_id:${id}` : `/`}`
    )
    return {
        type: 'STATES_FETCHED',
        payload: request
    }
}

export function setMapAnswer(answer) {
    return {
        type: 'ANSWER_MAP_FETCHED',
        payload: answer
    }
}

export function getListCourses() {
    const request = axios.get(`${process.env.REACT_APP_API_HOST}/courses/`)
    return {
        type: 'COURSES_FETCHED',
        payload: request
    }
}

export function create(values) {
    delete values.id
    if (!values.hasOwnProperty('trigger_question_id')) {
        values.trigger_question_id = null
    }
    return submit(values, 'post')
}

export function update(values) {
    // Removendo atributos do tipo Objeto.
    Object.keys(values).forEach(function(value, index) {
        if(values[value] && values[value].id) delete values[value]
    })
    if (!values.hasOwnProperty('trigger_question_id')) {
        values.trigger_question_id = null
    }
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete')
}

function submit(values, method) {
    return dispatch => {
        const id = values.id ? values.id + 0 : ''
        let filteredValues = { ...values }
        if (id) delete filteredValues.id
        let quiz_id = filteredValues.quiz_id
        delete filteredValues.quiz_id
        // Configurações para pessoa jurídica -> Sujeito a mudanças
        dispatch(getValidation({}))
        axios[method](`${process.env.REACT_APP_API_HOST}/answers/${id}`, filteredValues)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
                dispatch(getList(quiz_id))
            })
            .catch(e => {
                if (!e.response) {
                    toastr.error('Erro', 'Desconhecido :-/')
                    console.log(e)
                } else if (!e.response.data) {
                    toastr.error('Erro', e.response.message)
                } else if (e.response.data.errors) {
                    dispatch(getValidation(e.response.data.errors))
                } else if (e.response.data) {
                    toastr.error('Erro', e.response.data.message)
                }
            })
    }
}

export function showContent(flag) {
    return {
        type: 'QUESTION_ANSWER_CONTENT_CHANGED',
        payload: flag
    }
}

export async function showUpdate(payment) {
    const request = await axios.get(
        `${process.env.REACT_APP_API_HOST}/answers/${payment.id}`
    )
    payment.trigger_question_id = request.data.data.trigger_question_id
    return [
        showContent('form'),
        initialize('QuestionAnswerForm', payment)
    ]
}

export function init() {
    return [
        initialize('QuestionAnswerForm', INITIAL_VALUES),
        showContent('list')
    ]
}

export function getValidation(errors) {
    return {
        type: 'QUESTION_ANSWER_FORM_ERRORS',
        payload: errors
    }
}

export function getTriggers (answers) {
    const triggerList = answers.filter(e => e.trigger_question_id !== null).reduce((a, c) => {
        a.push(c.id);
        return a;
    }, []);
    return {
        type: 'QUESTION_ANSWER_FORM_ERRORS',
        payload: triggerList
    }
 }

//REDUCER
export const studentPaymentsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'QUESTION_ANSWER_FETCHED':
            return { ...state, list: action.payload.data ? action.payload.data.data : [] }

        case 'QUESTION_ANSWER_TYPES_FETCHED':
            return { ...state, listTypes: action.payload.data ? action.payload.data.data : [] }

        case 'QUESTION_ANSWER_CONTENT_CHANGED':
            return { ...state, show: action.payload }

        case 'QUESTION_ANSWER_TRIGGERS':
            return { ...state, triggerList: action.payload }

        case 'COURSES_FETCHED':
            return { ...state, courses: action.payload.data ? action.payload.data.data : [] }
        
        case 'STATES_FETCHED':
            return { ...state, classes: action.payload.data ? action.payload.data.data : [] }
        
        case 'ANSWER_MAP_FETCHED':
            return { ...state, map_answer: action.payload }

        default:
            return state;
    }
}