import React, { Component } from 'react'
import { connect } from 'react-redux'

import MenuItem from './MenuItem'
import MenuTree from './MenuTree'
import { menu as defaultMenu } from '../../default'
import { menu } from '../../app/exports'
import ProfileHeader from './ProfileHeader';

import { resetComponent } from '../../app/Quizzes/Duck';
import { resetComponent as resetComponentUser} from '../../default/users/actions';

const MainMenu = {...defaultMenu, ...menu}

class Menu extends Component {
    onResetComponent(path) {
        const option = {
            '/quizzes': () => this.props.resetComponent(),
            '/users': () => this.props.resetComponentUser()
        }
        const componentFunctionReset = option[path] === undefined ? () => null : option[path];
        return componentFunctionReset();
    }

    renderDinamicMenu(path, menuItem) {
        return <MenuItem
            onClick={() => this.onResetComponent(path)}
            key={path} path={path}
            label={menuItem.title} icon={menuItem.icon}
        />
    }

    render() {
        const scopes = this.props.scopes
        return (
            <div>
                <ProfileHeader/>
                <div className="mt-2">
                    <ul className='nav nav-pills nav-sidebar flex-column'>
                        {Object.keys(MainMenu).map((path) => {

                            const item = MainMenu[path]

                            if (item.fixed || scopes[path] || scopes[path.replace('/', '')]) {

                                if (item.children) {
                                    return <MenuTree
                                        key={path} path={path}
                                        label={item.title} icon={item.icon}
                                    >
                                        {Object.keys(item.children).map((childPath) => {
                                            return this.renderDinamicMenu(childPath, item.children[childPath])
                                        })}
                                    </MenuTree>

                                } else {
                                    return this.renderDinamicMenu(path, item)
                                }

                            } else if (path === ('/' || '')) {
                                return this.renderDinamicMenu(path, item)
                            }

                            return false
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({ scopes: state.auth.profile.scopes })
export default connect(mapStateToProps, { resetComponent, resetComponentUser })(Menu)