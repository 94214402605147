import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { showContent } from './Duck'
import ContentHeader from '../../common/template/ContentHeader'
import Content from '../../common/template/Content'
import If from '../../common/operator/If'

import List from './List'
import Form from './Form'

class Quizzes extends Component {

    render() {
        return (
            <div>
                <ContentHeader title='Questionarios' small=''
                    createMethod={() => this.props.showContent('form')}
                    titleButton="Adicionar Questionário" />
                <Content>
                    <If test={this.props.show === 'list'}>
                        <List />
                    </If>
                    <If test={this.props.show === 'form'}>
                        <Form />
                    </If>
                </Content>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    show: state.quizzes.show,
})

const mapDispatchToProps = dispatch => bindActionCreators({
   showContent
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Quizzes)
