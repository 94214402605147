import React, { Component } from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Row from '../../../common/layout/row'
import LabelAndInput from '../../../common/form/LabelAndInput'
import { init, getListTypes, getListStates } from '../Duck'
import LabelAndSelect from '../../../common/form/LabelAndSelect';
//import If from '../../../common/operator/If';

class FormDefault extends Component {
    constructor(props) {
        super(props)
        this.state = { tipo: 1 }
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <Row>
                    <Field name='active' component={LabelAndSelect} readOnly={false}
                        label='Status' cols='12 4' placeholder='Selecione...'
                        options={[
                            {id: false, name: "Desativado"},
                            {id: true, name: "Ativado"}
                        ]} error={this.props.errors} />
                    <Field name='open' component={LabelAndSelect} readOnly={false}
                        label='Público' cols='12 4' placeholder='Selecione...'
                        options={[
                            {id: false, name: "Não"},
                            {id: true, name: "Sim"}
                        ]} error={this.props.errors} />
                    <Field name='hash' component={LabelAndInput} disabled readOnly={this.props.readOnly}
                        label='Codigo externo' cols='12 4' error={this.props.errors} />
                </Row>
                <Row>
                    <Field name='name' component={LabelAndInput} readOnly={this.props.readOnly}
                        label='Nome' cols='12 6' placeholder='Informe o nome' error={this.props.errors} />
                    <Field name='description' component={LabelAndInput} readOnly={this.props.readOnly}
                        label={'Descrição'} cols='12 6' placeholder='Informe a descrição' error={this.props.errors} />
                </Row>
                <Row>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>{this.props.submitLabel}</button>
                    <button type='button' className='btn btn-default' onClick={this.props.init}>Cancelar</button>
                </Row>
            </form>
        );
    }
}
FormDefault = reduxForm({ form: 'QuizForm', destroyOnUnmount: false })(FormDefault)
const selector = formValueSelector('QuizForm')
const mapStateToProps = state => ({
    name: selector(state, 'name'),
    description: selector(state, 'description'),
    status: selector(state, 'status'),
    open: selector(state, 'open'),
    hash: selector(state, 'hash'),
    listProfiles: state.quizzes.listProfiles,
    states: state.quizzes.states,
    errors: state.quizzes.errors
})
const mapDispatchToProps = dispatch => bindActionCreators({ init, getListTypes, getListStates }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FormDefault)