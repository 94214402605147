import React from 'react'

const AccordionBody = (props) => {
    return (
        <div id={`collapse${props.id}`} className={`collapse ${props.show && 'show'}`} aria-labelledby={`heading${props.id}`} data-parent={`#${props.accordion}`}>
            {props.children}
        </div>
    )
}

export default AccordionBody