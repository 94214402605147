import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

// STATES
const INITIAL_STATE = {
    list: [],
    listProfiles: [],
    states: [],
    show: 'list',
    errors: {}
}

const INITIAL_VALUES = {
    name: '',
    description: '',
    open: false,
    hash: '',
    active: false
}

// ACTIONS
export function getList(page = null, limit = null, search = "") {
    const request = axios.get(
        `${process.env.REACT_APP_API_HOST}/quizzes${page !== null ? `?page=${page}&` : `?`}${limit !== null ? `limit=${limit}` : `limit=1`}${(search !== "" || search !== null) ? `&search=name:${search};&searchFields=name:like` : ``}`)
    return {
        type: 'QUIZ_FETCHED',
        payload: request
    }
}

export function getListTypes() {
    const request = axios.get(`${process.env.REACT_APP_API_HOST}/taxpayer_types`)
    return {
        type: 'QUIZ_TYPES_FETCHED',
        payload: request
    }
}

export function getListStates() {
    const request = axios.get(`${process.env.REACT_APP_API_HOST}/states`)
    return {
        type: 'STATES_FETCHED',
        payload: request
    }
}

export function create(values) {
    values.active = values.active === "false" ? 0 : 1
    values.open = values.open === "false" ? 0 : 1
    delete values.hash
    return submit(values, 'post')
}

export function update(values) {
    // Removendo atributos do tipo Objeto.
    delete values.hash
    Object.keys(values).forEach(function(value, index) {
        if(values[value] && values[value].id) delete values[value]
    })
    values.active = values.active === "false" ? 0 : 1
    values.open = values.open === "false" ? 0 : 1
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete')
}

function submit(values, method) {
    return dispatch => {
        const id = values.id ? values.id + 0 : ''
        let filteredValues = { ...values }
        if (id) delete filteredValues.id

        dispatch(getValidation({}))
        axios[method](`${process.env.REACT_APP_API_HOST}/quizzes/${id}`, filteredValues)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
                dispatch(getList(1, 5))
            })
            .catch(e => {
                if (!e.response) {
                    toastr.error('Erro', 'Desconhecido :-/')
                    console.log(e)
                } else if (!e.response.data) {
                    toastr.error('Erro', e.response.message)
                } else if (e.response.data.errors) {
                    dispatch(getValidation(e.response.data.errors))
                } else if (e.response.data) {
                    toastr.error('Erro', e.response.data.message)
                }
            })
    }
}

export function showContent(flag) {
    return {
        type: 'QUIZ_CONTENT_CHANGED',
        payload: flag
    }
}

export function showUpdate(student) {
    student.hash = `http://amplo.imesc.ma.gov.br/#/form/${student.hash}`
    return [
        showContent('form'),
        initialize('QuizForm', student)
    ]
}

export function init() {
    return [
        initialize('QuizForm', INITIAL_VALUES),
        showContent('list')
    ]
}

export function getValidation(errors) {
    return {
        type: 'QUIZ_FORM_ERRORS',
        payload: errors
    }
}

export function resetComponent() {
    return {
        type: 'QUIZ_RESET',
        payload: null
    }
}

//REDUCER
export const quizzesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'QUIZ_FETCHED':
            return { ...state, list: action.payload.data ? action.payload.data.data : [] }

        case 'QUIZ_TYPES_FETCHED':
            return { ...state, listProfiles: action.payload.data ? action.payload.data.data : [] }

        case 'QUIZ_CONTENT_CHANGED':
            return { ...state, show: action.payload }

        case 'QUIZ_FORM_ERRORS':
            return { ...state, errors: action.payload }

        case 'STATES_FETCHED':
            return { ...state, states: action.payload.data ? action.payload.data.data : [] }

        case 'QUIZ_RESET':
            return { ...INITIAL_STATE }

        default:
            return state;
    }
}