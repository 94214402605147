import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

// STATES
const INITIAL_STATE = {
    list: [],
    cities: [],
    shifts: [],
    show: 'list',
    errors: {}
}

const INITIAL_VALUES = {
    question_id: 0,
    quiz_id: 0,
    chart_type: ''
}

// ACTIONS
export function getList(id) {
    const request = axios.get(
        `${process.env.REACT_APP_API_HOST}/quiz_charts?search=quiz_id:${id}`
    )
    return {
        type: 'FORM_GRAPHS_FETCHED',
        payload: request
    }
}

export function getListShifts() {
    const request = axios.get(
        `${process.env.REACT_APP_API_HOST}/class_shifts`
    )
    return {
        type: 'SHIFTS_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post')
}

export function update(values) {
    // Removendo atributos do tipo Objeto.
    Object.keys(values).forEach(function(value, index) {
        if(values[value] && values[value].id) delete values[value]
    })
    return submit(values, 'put')
}

export function remove(values) {
    var c = window.confirm("Deseja apagar o gráfico?")
    if (c) {
        return submit(values, 'delete')
    }
    return false;
}

function submit(values, method) {
    return dispatch => {
        const id = values.id ? values.id + 0 : ''
        let filteredValues = { ...values }
        let quiz_id = filteredValues.quiz_id
        if (id) delete filteredValues.id

        // Configurações para pessoa jurídica -> Sujeito a mudanças
        dispatch(getValidation({}))
        axios[method](`${process.env.REACT_APP_API_HOST}/quiz_charts/${id}`, filteredValues)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
                dispatch(getList(quiz_id))
            })
            .catch(e => {
                if (!e.response) {
                    toastr.error('Erro', 'Desconhecido :-/')
                    console.log(e)
                } else if (!e.response.data) {
                    toastr.error('Erro', e.response.message)
                } else if (e.response.data.errors) {
                    dispatch(getValidation(e.response.data.errors))
                } else if (e.response.data) {
                    toastr.error('Erro', e.response.data.message)
                }
            })
    }
}

export function showContent(flag) {
    return {
        type: 'FORM_GRAPHS_CONTENT_CHANGED',
        payload: flag
    }
}

export function showUpdate(address) {
    return [
        showContent('form'),
        initialize('CourseClassesForm', address)
    ]
}

export function init() {
    return [
        initialize('CourseClassesForm', INITIAL_VALUES),
        showContent('list')
    ]
}

export function getValidation(errors) {
    return {
        type: 'FORM_GRAPHS_FORM_ERRORS',
        payload: errors
    }
}

//REDUCER
export const formGraphReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'FORM_GRAPHS_FETCHED':
            return { ...state, list: action.payload.data ? action.payload.data.data : [] }

        case 'FORM_GRAPHS_TYPES_FETCHED':
            return { ...state, listTypes: action.payload.data ? action.payload.data.data : [] }

        case 'FORM_GRAPHS_CONTENT_CHANGED':
            return { ...state, show: action.payload }

        case 'TAXPAYER_ADDRESS_FORM_ERRORS':
            return { ...state, errors: action.payload }

        case 'CITIES_FETCHED':
            return { ...state, cities: action.payload.data ? action.payload.data.data : [] }
        
        case 'SHIFTS_FETCHED':
            return { ...state, shifts: action.payload.data ? action.payload.data.data : [] }

        default:
            return state;
    }
}