 
import React, { Component } from 'react'

import ContentHeader from '../../common/template/ContentHeader'
import Content from '../../common/template/Content'
import InfoBox from '../../common/layout/widget/InfoBox'
import Row from '../../common/layout/row'
import Grid from '../../common/layout/grid'

export default class Dashboard extends Component {

    render() {

        return (
            <div>
                <ContentHeader title='Dashboard' small=' | Estatísticas instantâneas' />
                <Content>
                    <Row>
                        <Grid cols='12 12 8' style={{ margin: 0 }} >
                            <Row>
                                <InfoBox grid={{ cols: '12 6' }}
                                    icon='fa fa-handshake-o'
                                    bg='blue'
                                    title='Imesc Amplo'
                                    text='Seja bem vindo'
                                />
                                <InfoBox grid={{ cols: '12 6' }}
                                    icon='fa fa-graduation-cap'
                                    bg='blue'
                                    title='IMESC/SEPE - Governo do Maranhão'
                                    text='Versão 1.0.0'
                                />
                            </Row>
                        </Grid>
                    </Row>
                </Content>
            </div>
        )
    }
}