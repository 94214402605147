import React from 'react';
import FormAnswer from './FormAnswer';

const AnswerType = (props) => {
  switch (props.item.answer_type) {
    case "checkbox":
      return (
        <FormAnswer
          userId={props.userId}
          itemId={props.item.id}
          list={props.item.answer}
          questionsList={props.questionsList}
          submitLabel='Salvar'
          submitClass='primary'
          onRemove={props.onRemove}
          onUpdate={props.onUpdate}
        />
      );

    case "radio":
      return (
        <FormAnswer
          userId={props.userId}
          itemId={props.item.id}
          list={props.item.answer}
          questionsList={props.questionsList}
          submitLabel='Salvar'
          submitClass='primary'
          onRemove={props.onRemove}
          onUpdate={props.onUpdate}
        />
      )

    case "text":
      return (
        <input className='form-control' disabled value="" />
      )

    case "date":
      return (
        <input type="date" className='form-control' disabled value="dd/mm/aaaa" />
      )

    case "file":
      return (
        <input type="file" className='form-control' disabled value="" />
      )

    default:
      return (
        <input className='form-control' disabled value="" />
      )
  }
}

function AnswerList(props) {
  return (
    <>
      {AnswerType(props)}
    </>
  )
}

export default AnswerList;