import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getList, showUpdate, remove } from './actions'
import Table from '../../common/layout/Table';
import Paginate from '../../common/layout/Paginate';

class List extends Component {

    // componentWillMount() {
    //     this.props.getList();
    // }

    render() {
        return (
            <>
                { this.props.user?.login === 'admin' && <Paginate data={this.props.list} source={this.props.getList} />}
                <Table body={this.props.list?.data} actions={
                    (
                        this.props.user?.login === 'admin' ?
                            { update: this.props.showUpdate, remove: this.props.remove } :
                            null
                    )
                }
                    attributes={{ noun: 'Nome', description: 'Descrição' }} />
            </>
        )
    }
}

const mapStateToProps = state => ({ list: state.profile.list, user: state.auth.user })
const mapDispatchToProps = dispatch => bindActionCreators({ getList, showUpdate, remove }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)
