import React, { Component } from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Row from '../../../common/layout/row'
import { init, showUpdate, remove, showContent, create, update, getTriggers } from '../Duck/answer.duck'
import LabelAndSelect from '../../../common/form/LabelAndSelect';
import If from '../../../common/operator/If';
import Table from '../../../common/layout/Table';
import Content from '../../../common/template/Content';
import LabelAndInput from '../../../common/form/LabelAndInput';
import { Accordion, AccordionHeader, AccordionBody, AccordionAction } from '../../../common/layout/Accordion';
import AnswerList from './AnswerList'

class FormAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            isEdit: false,
            translate_course: [],
            translate_class: [],
            triggerList: []
        }
        this.init = this.init.bind(this)
        this.showUpdate = this.showUpdate.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.create = this.create.bind(this)
        this.onDelete = this.onDelete.bind(this)
    }

    onSubmit(answer) {
        answer.question_id = this.props.itemId
        answer.quiz_id = this.props.userId
        answer.trigger = (answer.trigger === 'true' || answer.trigger === true);
        this.setState({ showForm: false })
        if (answer.id) {
            this.props.update(answer)
        } else {
            this.props.create(answer)
        }
    }

    objectsAreSame(x, y) {
        var objectsAreSame = true;
        for (var propertyName in x) {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }

    onDelete(answer) {
        answer.quiz_id = this.props.userId
        this.props.remove(answer)
    }

    onDeleteTrigger(answer) {
        answer.quiz_id = this.props.userId
        this.props.onRemove(answer)
    }

    create() {
        this.props.showContent('form')
        this.setState({ showForm: true })
    }

    showUpdate(answer) {
        this.props.showUpdate(answer)
        this.setState({ showForm: true })
    }

    showUpdateTrigger(answer) {
        this.props.onUpdate(answer)
    }


    init() {
        this.props.init()
        this.setState({ showForm: false })
    }

    filterTriggers(trigger_list, list) {
        // const triggerList = list.filter(e => e.trigger_question_id !== null).reduce((a, c) => {
        //     a.push(c.trigger_question_id);
        //     return a;
        // }, []);
        const result = [].concat(...list.map(el => trigger_list.filter(e => e.trigger_answer_id === el.id)));
        //debugger
        if (result.length > 0) {
            this.setState({
                triggerList: result
            })
        }
    }

    componentDidMount() {
        const { trigger_list, list } = this.props
        this.filterTriggers(trigger_list, list);
    }

    componentWillUpdate(nextProps) {
        if (nextProps.itemId !== undefined && nextProps.itemId !== null && this.props.itemId === null) {
            nextProps.getList(nextProps.itemId)
        }

        if (!this.objectsAreSame(nextProps.trigger_list, this.props.trigger_list)) {
            this.filterTriggers(nextProps.trigger_list, nextProps.list);
        }
    }

    change_classes = (value) => {
        if (value !== null || value !== undefined) {
            this.props.getListClass(value)
        }
    }

    handleDebug = () => {
        console.log(this.props.questionsList);
    }

    render() {
        return (
            <>
                <If test={this.state.showForm === true}>
                    <Content>
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <Row>
                                <Field name='answer_name' type={'text'} component={LabelAndInput} readOnly={false}
                                    label='Resposta' cols='12 12' placeholder='escreva uma opção de resposta' error={this.props.errors} />
                            </Row>
                            <Row>
                                <Field name='trigger' component={LabelAndSelect} readOnly={false}
                                    label='Resposta gatilho? (chama outra pergunta?)' cols='12 12' placeholder='Selecione...'
                                    options={[
                                        { id: false, name: 'Não' },
                                        { id: true, name: 'Sim' }
                                    ]} error={this.props.errors} />
                            </Row>
                            <If test={this.props.trigger === "true" || this.props.trigger === true}>
                                <Row>
                                    <Field name='trigger_question_id' component={LabelAndSelect} readOnly={false}
                                        label='Selecione a pergunta gatilho' cols='12 12' placeholder='Selecione...' multiple={true}
                                        options={[].concat(this.props.questionsList, this.props.trigger_list).map(question => {
                                            return ({
                                                id: question.id,
                                                name: question.name_question
                                            })
                                        })} error={this.props.errors} />
                                </Row>
                            </If>
                            <Row>
                                <button type='submit' className={`btn btn-${this.props.submitClass}`}>{this.props.submitLabel}</button>
                                <button type='button' className='btn btn-default' onClick={this.init}>Cancelar</button>
                            </Row>
                        </form>
                    </Content>
                </If>
                <If test={this.state.showForm === false}>
                    <button className="btn btn-primary" onClick={this.create}>Cadastrar Resposta</button>
                    <br /><br />
                    <Table body={this.props.list} labelMobile="answer_name"
                        actions={{ update: this.showUpdate, remove: this.onDelete }}
                        attributes={{ answer_name: "Resposta", trigger: "Gatilho?" }}
                        translate={{
                            trigger: [
                                { id: false, name: 'Não' },
                                { id: true, name: 'Sim' }
                            ]
                        }}
                    />
                    <>
                        <p>Perguntas gatilho:</p>
                        {
                            this.state.triggerList.map((item, index) => (
                                <Accordion key={index}>
                                    <AccordionHeader id={index} color="#bfbfbf">
                                        <div className="row">
                                            <div className="col-md-10">
                                                <AccordionAction id={index}>
                                                    <p style={{ textAlign: 'justify', color: "#222" }}>{`${index + 1} - ${item.name_question}`}</p>
                                                </AccordionAction>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="float-right">
                                                    <button style={{ backgroundColor: "#bfbfbf" }} className='btn btn-warning col-5' onClick={() => this.showUpdateTrigger(item)}>
                                                        <i className='fa fa-edit'></i>
                                                    </button>
                                                    <button style={{ backgroundColor: "#bfbfbf" }} className='btn btn-warning col-5' onClick={() => this.onDeleteTrigger(item)}>
                                                        <i className='fa fa-trash'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionBody id={index} accordion="accordion" show={true}>
                                        <div className="card-body ml-4 mr-4">
                                            <AnswerList
                                                item={item}
                                                userId={this.props.userId}
                                                questionsList={this.props.list}
                                                onRemove={this.props.createPayment}
                                                onUpdate={this.props.updatePayment}
                                            />
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            ))
                        }
                    </>
                </If>
            </>
        );
    }
}

FormAnswer = reduxForm({ form: 'QuestionAnswerForm', destroyOnUnmount: false })(FormAnswer)
const selector = formValueSelector('QuestionAnswerForm')
const mapStateToProps = state => ({
    answer_name: selector(state, 'answer_name'),
    question_id: 0,
    trigger: selector(state, 'trigger'),
    trigger_question_id: selector(state, 'trigger_question_id'),
    errors: state.quiz_questions_answers.errors,
    listTypes: state.quiz_questions_answers.listTypes,
    courses: state.quiz_questions_answers.courses,
    show: state.quiz_questions_answers.show,
    classes: state.quiz_questions_answers.classes,
    trigger_list: state.quiz_questions.triggerList,
    trigger_answers: state.quiz_questions_answers.triggerList,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    init, showUpdate, remove, showContent, create, update, getTriggers
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FormAnswer)