import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import TabsHeader from '../../../common/layout/tab/tabsHeader';
import { update, create } from '../Duck'
import { update as updateQuestions, create as createQuestions } from '../Duck/questions.duck'
import { update as updateChart, create as createChart } from '../Duck/graphs.duck'
import FormDefault from './FormDefault';
import FormQuestions from './FormQuestions';
import FormQuizAnswers from './FormQuizAnswers';
import FormGraphs from './FormGraphs';
import L from 'leaflet'
import { setMapAnswer } from '../Duck/answer.duck';
// import "leaflet/dist/leaflet.css";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            isEdit: false,
            isPj: false,
            map: false
        }
        this.myRef = React.createRef();
    }

    dateFormat = i => {
        if(i) {
            let date = new Date(i + ( i.length > 11 ? '' : ' 00:00:00'))
            date = (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' 
            + (date.getMonth() < 9 ? '0' : '') + (date.getMonth()+1) + '/' + date.getFullYear()
    
            return date
        } else return ''
    }

    componentWillUpdate(nextProps) {
        if (nextProps.quiz.values !== undefined && this.state.id === null) {
            this.setState({
                id: nextProps.quiz.values.id
            })
        }
    }

    setAnswerMap(e) {
        console.log(e)
        this.props.setMapAnswer([e]);
    }

    render() {

        const quizAnswers = this.props.quizAnswers

        setTimeout(() => {
            if(this.myRef.current && quizAnswers.length > 0) {
                let map = this.state.map
                if(!map) {
                    map = L.map('answersMap').setView([-2.54934124, -44.20194795], 10);

                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    }).addTo(map);

                    quizAnswers.map((e, index) => {

                        let htmlAnswer = ''

                        e.answers.map((answer, index) => {
                            let renderedAnswer = null

                            switch(answer.answer_type) {
                                case 'text':
                                case 'number':
                                    renderedAnswer = answer.answer
                                    break
        
                                case 'radio':
                                case 'checkbox':
                                    renderedAnswer = answer.answer.answer_name
                                    break
        
                                case 'file':
                                    renderedAnswer = '<a href="'+answer.answer+'" target="_blank">Clique para baixar</a>'
                                    break
        
                                case 'date':
                                    renderedAnswer = this.dateFormat(answer.answer)
                                    if(renderedAnswer.indexOf('NaN') > -1) renderedAnswer = answer.answer
                                    break
                                default:
                                    console.log("ok");
                                    break
                            }
        
                            htmlAnswer = htmlAnswer + '<div className="row"><strong>'+(answer.name || answer.title)+'</strong>: '+renderedAnswer+'</div>'
                            return true
                        })

                        htmlAnswer = '<div className="row"><strong>Entrevistado</strong> : '+e.interviewer+'</div>' + htmlAnswer
                        
                        // console.log(e)
                        L.marker([e.lat, e.long]).addTo(map)
                        .bindPopup(htmlAnswer)
                        .on('click', () => this.setAnswerMap(e))
                        // .openPopup()
                        return true;
                    } )

                    this.setState({map})
                }
                
            }
        },3000)
        return (
            <>
                <div className="card card-primary">
                    <TabsHeader>
                        <li className="nav-item">
                            <a className={`nav-link active`} data-toggle="tab" href="#home">Dados Gerais</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${this.props.isEdit ? `` : `disabled`}`} data-toggle="tab" href="#menu1">Perguntas</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className={`nav-link ${this.props.isEdit ? `` : `disabled`}`} data-toggle="tab" href="#menu2">Respostas/Mapa</a>
                        </li> */}
                        <li className="nav-item">
                            <a className={`nav-link ${this.props.isEdit ? `` : `disabled`}`} data-toggle="tab" href="#menu3">Respostas/Mapa</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${this.props.isEdit ? `` : `disabled`}`} data-toggle="tab" href="#menu4">Gráficos</a>
                        </li>
                    </TabsHeader>
                    <div className='card-body'>
                        <div className="tab-content">
                            <div className="tab-pane container active" id="home">
                                <FormDefault onSubmit={this.props.isEdit ? this.props.update : this.props.create}
                                    submitLabel='Salvar' submitClass='primary' />
                            </div>
                            <div className="tab-pane container fade" id="menu1">
                                <FormQuestions userId={this.state.id} submitLabel='Salvar' submitClass='primary'
                                    onCreate={this.props.createQuestions} onUpdate={this.props.updateQuestions} />
                            </div>
                            <div className="tab-pane container fade" id="menu2">
                                {/* <FormQuizAnswers userId={this.state.id} submitLabel='Salvar' submitClass='primary'
                                    onCreate={this.props.createQuestions} onUpdate={this.props.updateQuestions} /> */}
                            </div>
                            <div className="tab-pane container fade" id="menu3">
                                <div id='answersMap' ref={this.myRef} style={{ height: '600px' }}></div>
                                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
                                integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
                                crossOrigin=""/>
                                <FormQuizAnswers userId={this.state.id} submitLabel='Salvar' submitClass='primary'
                                    onCreate={this.props.createQuestions} onUpdate={this.props.updateQuestions} />
                            </div>
                            <br/>
                            <div className="tab-pane container fade" id="menu4">
                                <FormGraphs userId={this.state.id} submitLabel='Salvar' submitClass='primary'
                                    onCreate={this.props.createChart} onUpdate={this.props.updateChart}/>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    isEdit: state.form.QuizForm && state.form.QuizForm.initial && state.form.QuizForm.initial.id > 0,
    quiz: state.form.QuizForm,
    quizAnswers: state.quiz_quiz_answers.list,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    update, create, updateQuestions, createQuestions, updateChart, createChart
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Form)