import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getList, showUpdate, remove } from './Duck'
import { getList as getListProfiles } from '../../default/profiles/actions'
import Table from '../../common/layout/Table';
import Paginate from '../../common/layout/Paginate';

class List extends Component {

    componentWillMount() {
        this.props.getListProfiles();
    }

    render() {
        return (
            <>
                <Paginate data={this.props.list} source={this.props.getList}/>
                <Table body={this.props.list?.data} actions={{ update: this.props.showUpdate, remove: this.props.remove }}
                    attributes={{ name: "Nome", profile_id: "Grupo", description: "Descrição", active: "Ativo?" }}
                    translate={{
                        active: [
                            { id: false, name: 'Não' },
                            { id: true, name: 'Sim' }
                        ],
                        profile_id: this.props.profile_list
                    }} />
            </>
        )
    }
}

const mapStateToProps = state => ({ list: state.quizzes.list, profile_list: state.profile.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getList, showUpdate, remove, getListProfiles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)