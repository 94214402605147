import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

// STATES
const INITIAL_STATE = {
    list: [],
    courses: [],
    classes: [],
    show: 'list',
    errors: {}
}

const INITIAL_VALUES = {
    name_question: 0,
    quiz_id: 0,
    id: 0,
    answer_type: 0

}

// ACTIONS
export function getList(id) {
    const request = axios.get(
        `${process.env.REACT_APP_API_HOST}/quizz_responses?search=quiz_id:${id}`
    )
    return {
        type: 'QUIZ_QUIZ_ANSWERS_FETCHED',
        payload: request
    }
}

export function getListClass(id = null) {
    const request = axios.get(
        `${process.env.REACT_APP_API_HOST}/course_classes${id !== null ? `?search=course_id:${id}` : `/`}`
    )
    return {
        type: 'STATES_FETCHED',
        payload: request
    }
}

export function getListCourses() {
    const request = axios.get(`${process.env.REACT_APP_API_HOST}/courses/`)
    return {
        type: 'COURSES_FETCHED',
        payload: request
    }
}

export function create(values) {
    delete values.id
    return submit(values, 'post')
}

export function update(values) {
    // Removendo atributos do tipo Objeto.
    Object.keys(values).forEach(function(value, index) {
        if(values[value] && values[value].id) delete values[value]
    })
    delete values.answer
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete')
}

function submit(values, method) {
    return dispatch => {
        const id = values.id ? values.id + 0 : ''
        let filteredValues = { ...values }
        if (id) delete filteredValues.id

        // Configurações para pessoa jurídica -> Sujeito a mudanças
        dispatch(getValidation({}))
        axios[method](`${process.env.REACT_APP_API_HOST}/quizz_responses/${id}`, filteredValues)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
                dispatch(getList(resp.data.data.quiz_id|| filteredValues.quiz_id))
            })
            .catch(e => {
                if (!e.response) {
                    toastr.error('Erro', 'Desconhecido :-/')
                    console.log(e)
                } else if (!e.response.data) {
                    toastr.error('Erro', e.response.message)
                } else if (e.response.data.errors) {
                    dispatch(getValidation(e.response.data.errors))
                } else if (e.response.data) {
                    toastr.error('Erro', e.response.data.message)
                }
            })
    }
}

export async function getXlsfile(id){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/quiz_spreadsheets/`, {
        quiz_id: id
    }, {
        responseType: 'blob', // important
    })
}

export function showContent(flag) {
    return {
        type: 'QUIZ_QUIZ_ANSWERS_CONTENT_CHANGED',
        payload: flag
    }
}

export function showUpdate(address) {
    return [
        showContent('form'),
        initialize('QuizQuestionsForm', address)
    ]
}

export function init() {
    return [
        initialize('QuizQuestionsForm', INITIAL_VALUES),
        showContent('list')
    ]
}

export function getValidation(errors) {
    return {
        type: 'QUIZ_QUIZ_ANSWERS_FORM_ERRORS',
        payload: errors
    }
}

//REDUCER
export const quizQuizAnswersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'QUIZ_QUIZ_ANSWERS_FETCHED':
            return { ...state, list: action.payload.data ? action.payload.data.data : [] }

        case 'QUIZ_QUIZ_ANSWERS_TYPES_FETCHED':
            return { ...state, listTypes: action.payload.data ? action.payload.data.data : [] }

        case 'QUIZ_QUIZ_ANSWERS_CONTENT_CHANGED':
            return { ...state, show: action.payload }

        case 'TAXPAYER_ADDRESS_FORM_ERRORS':
            return { ...state, errors: action.payload }

        case 'COURSES_FETCHED':
            return { ...state, courses: action.payload.data ? action.payload.data.data : [] }
        
        case 'STATES_FETCHED':
            return { ...state, classes: action.payload.data ? action.payload.data.data : [] }

        default:
            return state;
    }
}