import React from "react";
import Axios from 'axios'

export const getYYYYMMDD = () => {
    const d = new Date()
    const dates = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().split('T')
    return dates.join(" ").split(".")[0]
}

const amploApi = Axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImVkYTMxZDAyNmZhM2Y3NjU1OWU2NmU1ZWU3NWMzYTNjNDM4NDgxYTMwNDUxMzU3MThkYjg0MThjMWM1MWMyYzI0YTBmZWVlMzk2YmFlYzY2In0.eyJhdWQiOiIxIiwianRpIjoiZWRhMzFkMDI2ZmEzZjc2NTU5ZTY2ZTVlZTc1YzNhM2M0Mzg0ODFhMzA0NTEzNTcxOGRiODQxOGMxYzUxYzJjMjRhMGZlZWUzOTZiYWVjNjYiLCJpYXQiOjE2Mjg1MjI2NTIsIm5iZiI6MTYyODUyMjY1MiwiZXhwIjoxNjYwMDU4NjUyLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.HSVS4fREYNtksygfAuKVvmy2n-6AzXwODhjKoF8xy5o8EazeOthZ-oXCxypc8tTcYvjqYnZaoHBIzDlzRMyyu8MHNN2HMWoYoUQmvJry_ErpWci6J1AHtXWPX-YEmqrYy6bFnHxNaMWq1DJtGGw4TnfJ2ymAeIOcSXBPiHOdE3KsZB-m8lA3B20geV8zbyP-L5ILtVrtdb5BHH3AdJyqKZNeD9oa9qeMVB3kiyLZyMyubVlsOALqYPpP20BolkJO6qN35qHu4GBTtPQosZMlKcMFZothljSQeie0JoBrHmNJzcdjy_dyA-KcHRIkmoU5b8gk4foMouUMLWTzTj3DKHEsGrJsue0ozUq35vHkI-Zp6XG2eAQf5Vuw543xOUqdzuasM1QvnvnxbEMte3naOCySOhgQrlqI2mc_cxT_k6Ao0HsRJdoIS2rKOcZPO8MVsPM8Qi4Q1EeIeWp90oBQ_ewVjbLlUMi8cyGQ5fRXmKknfOIjwT6G-pDNIz79SsAOr2CPZ_yMCRwGvZ-S29ASsHLAYu6o-hEnI2XBsDQF-FUdpRnQb7hbbIE9jRmxZu4EoTlhFkGX6B7g3jSaQqmOdIbO9-p8WIgWb0XDEpVYlv6DjjPa1thnaSu1d-4_PZu-gS_ANBIGiUS5eLwJvU0uXi2jrHosXK_3-7MyU0oeJVY'
    }
  });

const filterTrigger = questions => {
    const isTrigger = questions.filter(q => q.is_from_trigger === 1);
    const isntTrigger = questions.filter(q => q.is_from_trigger === 0);

    return {
        questData: isntTrigger,
        questDataTrigger: isTrigger
    }
}

export const getQuestions = (hash) => {
    return amploApi.get(`quizzes/${hash}`)
    .then(el => { 
        return {quiz_id: el.data.data.id , title_form: el.data.data.name, description_form: el.data.data.description, ...filterTrigger(el.data.data.questions) }
    })
    .catch(err => {
        if (err.request.status === 404) {
            return {quiz_id: 0 , questData: [], title_form: "Imesc/SEPE Amplo", description_form: "Questionário não encontrado" }
        }
        return {quiz_id: 0 , questData: [], title_form: "Erro ao carregar Form", description_form: err.message }
    })
}

export const sendQuestions = (response) => {
    return amploApi.post('response_lists', {
        list: [response]
    })
    .then(el => { 
        return true
    })
    .catch(err => {
        return err
    })
}

export const resolveAnswer = (values, quest) => {
    const options = {
        text: () => values[`@${quest.id}`] || "",
        number: () => values[`@${quest.id}`] || "",
        date: () => values[`@${quest.id}`] || "",
        file: () => values[`@${quest.id}`][0].base64 || "",
        radio: () => {
            const resp = values[`@${quest.id}`];
            const [filtered] = quest.answers.filter(a => a.answer_name === resp);
            return {
                id: filtered.id,
                answer_name: filtered.answer_name
            };
        },
        checkbox: () => {
            const resp = values[`@${quest.id}`];
            return resp.map(r => {
                const [filtered = {}] = quest.answers.filter(a => a.answer_name === r);
                return {
                    id: filtered.id,
                    answer_name: filtered.answer_name
                };
            })
        },
    }
    return options[quest.answer_type]();
}


const handleChange = (event, input) => {
    event.preventDefault();
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    let imageFile = event.target.files[0];
    if (imageFile) {
      const localImageUrl = URL.createObjectURL(imageFile);
      const imageObject = new window.Image();

      imageObject.onload = async () => {
        imageFile.width = imageObject.naturalWidth;
        imageFile.height = imageObject.naturalHeight;
        imageFile.base64 = await toBase64(imageFile);
        input.onChange(imageFile.base64);
        URL.revokeObjectURL(imageFile);
      };
      imageObject.src = localImageUrl;
    }
  };


export const renderInput = ({ input, type, meta }) => {
    return (
      <div>
        <input
          {...input}
          name={input.name}
          type={type}
          value={undefined}
          onChange={event => handleChange(event, input)}
        />
      </div>
    );
  };