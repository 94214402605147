import React, { Component } from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Row from '../../../common/layout/row'
import { init, getList, showUpdate, remove, showContent, updatedQuiz } from '../Duck/questions.duck'
import LabelAndSelect from '../../../common/form/LabelAndSelect';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import LabelAndInput from '../../../common/form/LabelAndInput';
import If from '../../../common/operator/If';
import Content from '../../../common/template/Content';
import { Accordion, AccordionHeader, AccordionBody, AccordionAction } from '../../../common/layout/Accordion';
import AnswerList from './AnswerList';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "#fff",
    padding: grid
});

class FormQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            isEdit: false,
            translate_course: [],
            translate_class: [],
            new_list: []
        }
        this.init = this.init.bind(this)
        this.showUpdate = this.showUpdate.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.create = this.create.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onSubmit(question) {
        question.quiz_id = this.props.userId

        if (question.id) {
            this.props.onUpdate(question)
        } else {
            this.props.onCreate(question)
        }
    }

    create() {
        this.props.showContent('form')
    }

    showUpdate(question) {
        this.props.showUpdate(question)
    }

    init() {
        this.props.init()
    }

    componentDidMount() {
        if (this.props.userId !== null) {
            this.props.getList(0)
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.userId !== undefined && nextProps.userId !== null && this.props.userId === null) {
            nextProps.getList(nextProps.userId)
        }
    }

    change_classes = (value) => {
        if (value !== null || value !== undefined) {
            this.props.getListClass(value)
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        this.props.updatedQuiz(
            this.props.list,
            result.source.index,
            result.destination.index,
            this.props.userId
        );
    }

    nextCharacter = (c) => {
        return String.fromCharCode(c.charCodeAt(0) + 1);
    }

    createViewRow = (item) => {
        return <View style={styles.tableRow}>{item}</View>
    }

    createViewMultipleOptions = (item) => {
        var alpha = "a";
        var col = 0;
        var _item = [];
        var _subitem = [];
        item.answer.map(i => {
            var resp = `${alpha}) ${i.answer_name}   `
            alpha = this.nextCharacter(alpha);
            col = col+1;
            if (col > 4) {
                _item.push(this.createViewRow(_subitem));
                _subitem = [];
                col = 1;
            }
            _subitem.push(<View style={styles.tableCol}>
                <Text style={styles.section2}>{resp}</Text>
            </View>);
        })

        if (_subitem.length > 0) {
            _item.push(this.createViewRow(_subitem));
        }

        return _item;
    }

    MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={styles.title}>{this.props.quiz?.values?.name}</Text>
                {
                    this.props.list.map((item, index) => {
                        return (
                            <View wrap={false}  style={styles.sectionTitle}>
                                <Text>{index + 1}-{item.name_question}</Text>
                                {
                                    item.answer_type === "checkbox" || item.answer_type === "radio" ?
                                        this.createViewMultipleOptions(item) : (
                                            <Text style={styles.section}>R:</Text>
                                        )
                                }
                            </View>
                        )
                    })
                }
            </Page>
        </Document>
    );


    render() {
        // const { AnswerList } = this
        return (
            <>
                <If test={this.props.show === 'form'}>
                    <Content>
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <Row>
                                <Field name='answer_type' component={LabelAndSelect} readOnly={false}
                                    label='Escolha o Tipo de pergunta' cols='12 12' placeholder='Selecione...'
                                    options={[
                                        { id: "text", name: "Tipo Texto" },
                                        { id: "number", name: "Campo numérico" },
                                        { id: "date", name: "Data do dia" },
                                        { id: "checkbox", name: "Multipla escolha" },
                                        { id: "radio", name: "Escolha única" },
                                        { id: "file", name: "Arquivo" },
                                    ]} error={this.props.errors} />
                            </Row>
                            <Row>
                                <Field name='name_question' component={LabelAndInput} readOnly={false}
                                    label='Descrição da pergunta.' cols='12 12' placeholder='Selecione...'
                                    error={this.props.errors} />
                            </Row>
                            <Row>
                                <button type='submit' className={`btn btn-${this.props.submitClass}`}>{this.props.submitLabel}</button>
                                <button type='button' className='btn btn-default' onClick={this.init}>Voltar</button>
                            </Row>
                        </form>
                    </Content>
                </If>
                <If test={this.props.show === 'list'}>
                    <div className="btn-group">
                        <button className="btn btn-primary" onClick={this.create}>Adicionar Pergunta</button>
                        <PDFDownloadLink key={Math.random()} className="btn btn-danger" document={<this.MyDocument />} fileName="somename.pdf">
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Exibir Questionario (PDF)')}
                        </PDFDownloadLink>
                    </div>
                    <br /><br />
                    <div id="accordion">
                        {
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {this.props.list.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Accordion key={index}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>
                                                            <AccordionHeader id={index}>
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <AccordionAction id={index}>
                                                                            <p style={{ textAlign: 'justify', color: "#eee" }}>
                                                                                {`${index + 1} - ${item.name_question}`}
                                                                            </p>
                                                                        </AccordionAction>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="float-right">
                                                                            <button style={{ backgroundColor: "#343a40" }} className='btn btn-primary col-5' onClick={() => this.showUpdate(item)}>
                                                                                <i className='fa fa-edit'></i>
                                                                            </button>
                                                                            <button style={{ backgroundColor: "#343a40" }} className='btn btn-danger col-5' onClick={() => this.props.remove(item)}>
                                                                                <i className='fa fa-trash'></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </AccordionHeader>
                                                            <AccordionBody id={index} accordion="accordion" show={true}>
                                                                <div className="card-body ml-4 mr-4">
                                                                    <AnswerList
                                                                        item={item}
                                                                        userId={this.props.userId}
                                                                        questionsList={this.props.list}
                                                                        onRemove={this.props.remove}
                                                                        onUpdate={this.props.showUpdate}
                                                                    />
                                                                </div>
                                                            </AccordionBody>
                                                        </Accordion>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        }
                    </div>
                </If>
            </>
        );
    }
}
// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: 12,
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    section: {
        margin: 5,
        padding: 5,
        flexGrow: 1,
        flexDirection: 'row',
        textAlign: 'justify',
    },
    tableRow: { 
        flexDirection: "row" 
    }, 
    tableCol: { 
        width: "25%",
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      },
    section2: {
        margin: 5,
        padding: 5,
        width: "100%",
        flexGrow: 1,
    },
    sectionTitle: {
        margin: 5,
        padding: 5,
        //flexGrow: 1,
        fontWeight: "bold"
    },
    title: {
        fontSize: 20,
        margin: 5,
        textAlign: 'center',
    }
});
FormQuestions = reduxForm({ form: 'QuizQuestionsForm', destroyOnUnmount: false })(FormQuestions)
const selector = formValueSelector('QuizQuestionsForm')
const mapStateToProps = state => ({
    name_question: selector(state, 'name_question'),
    answer_type: selector(state, 'answer_type'),
    quiz_id: 0,
    errors: state.quiz_questions.errors,
    list: state.quiz_questions.list,
    show: state.quiz_questions.show,
    listTypes: state.quiz_questions.listTypes,
    quiz: state.form.QuizForm
})
const mapDispatchToProps = dispatch => bindActionCreators({
    init, getList, showUpdate, remove, showContent, updatedQuiz
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FormQuestions)