import React from 'react'
import { HashRouter as Router } from 'react-router-dom'

// import Footer from '../common/template/Footer'
import ExternalRoutes from './externalRoutes'

export default props => (
    <Router>
        <div>
            <ExternalRoutes />
            {/* <Footer /> */}
        </div>
    </Router>
)